<template>
  <div
    class="column is-12 h-full"
  >
    <iframe
      :src="field.properties.basic.url"
      frameBorder="0"
      scrolling="auto"
      :height="field.properties.basic.height ? '100%' : '800px'"
      width="100%"
    />
  </div>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};
export default __sfc_main;
</script>
